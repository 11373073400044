import { inject, Injectable } from '@angular/core';
import { CrmDictionary } from 'common-module/core/types';
import { crmSafeListByIDs } from 'common-module/list';
import { CrmMessageService } from 'common-module/message';
import { compact, uniq } from 'lodash-es';
import {
  BehaviorSubject,
  map,
  Observable,
  of,
  switchMap,
  take,
  throwError,
} from 'rxjs';

import { isHexString } from '~/shared/utils/string/is-hex';

import { UserOrganization } from '../user/groups/user-organization';
import { UsersGroupsApiService } from '../user/groups/users-groups-api.service';
import { isCohortNonMemberMembership } from '../user/membership/is-cohort-membership';
import { UserModel } from '../user/model/user.model';

export interface Organization {
  id: string;
  name: string;
}

export const ORG_LS_KEY = '__bhv_organization';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
  organizations$ = new BehaviorSubject<Organization[]>([]);
  currentOrganization$!: BehaviorSubject<string>;

  protected groups = inject(UsersGroupsApiService);
  protected message = inject(CrmMessageService);

  get organization$() {
    return this.currentOrganization$.pipe(take(1));
  }

  get organizationData$() {
    return this.organization$.pipe(
      switchMap((organization) => {
        return this.organizations$.pipe(
          take(1),
          map((organizations) => {
            return organizations.find(
              (org) => org.id === organization,
            ) as Organization;
          }),
        );
      }),
    );
  }

  getOrganizations(user: UserModel) {
    if (this.organizations$.value.length) {
      return of(this.organizations$.value);
    }

    return this.getUserOrganizations(user).pipe(
      switchMap((organizations) =>
        crmSafeListByIDs(
          (params) =>
            this.groups.listOrganizations({ ...params, '$sort[name]': '1' }),
          organizations,
          20,
        ),
      ),
      map<UserOrganization[], Organization[]>((organizations) =>
        organizations.map((org) => ({ id: org._id, name: org.name })),
      ),
      switchMap((organizations) => {
        if (organizations.length === 0) {
          this.message.error('You have no assigned organizations!');
          return throwError(() => 'NONE_ORGANIZATIONS');
        }

        this.organizations$.next(organizations);

        return of(organizations);
      }),
    );
  }

  /**
   * Create current organization subject if not exists with selected organization and return true
   *
   * If current organization is same as selected organization, do nothing and return false
   *
   * Otherwise, select organization and return true
   *
   * @param organization
   */
  setCurrentOrganization(organization: string) {
    if (!this.currentOrganization$) {
      this.currentOrganization$ = new BehaviorSubject<string>(organization);
      return true;
    }

    if (this.currentOrganization$.value === organization) {
      return false;
    }

    this.currentOrganization$.next(organization);
    return true;
  }

  /**
   * If select current organization succeed, save selected organization to local storage and return true
   *
   * Otherwise, return false
   *
   * @param organization
   */
  setAndSaveOrganization(organization: string) {
    if (this.setCurrentOrganization(organization)) {
      localStorage.setItem(ORG_LS_KEY, organization);
      return true;
    }

    return false;
  }

  getDefaultCommunity() {
    return this.groups.getDefaultCommunity(this.currentOrganization$.value);
  }

  getDefaultCohort() {
    return this.groups.getDefaultCohort(this.currentOrganization$.value);
  }

  listPrograms(params?: CrmDictionary) {
    return this.organization$.pipe(
      switchMap((organization) =>
        this.groups.listPrograms({
          ...params,
          'organization._id': organization,
        }),
      ),
    );
  }

  listCohortsAll(params?: CrmDictionary) {
    return this.organization$.pipe(
      switchMap((organization) =>
        this.groups.listCohortsAll({
          ...params,
          'organization._id': organization,
        }),
      ),
    );
  }

  listCommunitiesAll(params?: CrmDictionary) {
    return this.organization$.pipe(
      switchMap((organization) =>
        this.groups.listCommunitiesAll({
          ...params,
          'organization._id': organization,
        }),
      ),
    );
  }

  listCommunitiesSafe(ids: string[], params?: CrmDictionary) {
    return this.organization$.pipe(
      switchMap((organization) =>
        this.groups.listCommunitiesSafe(ids, {
          ...params,
          'organization._id': organization,
        }),
      ),
    );
  }

  isOrganizationAdmin(user: UserModel): Observable<boolean> {
    return this.organization$.pipe(
      map((organization) => {
        if (user.roles.admin) {
          return true;
        }

        if (!user.membership) {
          return false;
        }

        const adminMembership = user.membership.find(
          ({ type, role, ref }) =>
            type === 'organization' && role === 'admin' && ref === organization,
        );

        return !!adminMembership;
      }),
    );
  }

  loadCurrentOrganizationFromStorage(): string | null {
    const savedOrganization = localStorage.getItem(ORG_LS_KEY);
    if (!savedOrganization) {
      return null;
    }

    if (savedOrganization.length === 24 && isHexString(savedOrganization)) {
      return savedOrganization;
    }

    this.removeSavedOrganization();
    return null;
  }

  removeSavedOrganization() {
    localStorage.removeItem(ORG_LS_KEY);
  }

  protected getUserOrganizations(user: UserModel): Observable<string[]> {
    if (user.roles.admin) {
      return this.groups
        .listCohortsAll()
        .pipe(
          map((response) =>
            uniq(response.map(({ organization }) => organization?._id)),
          ),
        );
    } else {
      const organizations = (user.membership ?? [])
        .filter(isCohortNonMemberMembership)
        .map((ms) => ms.organization?._id);

      return of(uniq(compact(organizations)));
    }
  }
}
