import { HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CrmDictionary } from 'common-module/core/types';
import {
  CrmEndpointDecorator,
  CrmEndpointListResponse,
} from 'common-module/endpoint';
import { crmSafeListByIDs } from 'common-module/list';
import { CrmUserService } from 'common-module/user';
import { Settings, SystemZone } from 'luxon';
import { GaActionEnum } from 'ngx-google-analytics';
import {
  catchError,
  EMPTY,
  finalize,
  map,
  Observable,
  of,
  share,
  switchMap,
  tap,
} from 'rxjs';

import { getEmptyResponse } from '~/shared/model/list/empty-response';
import { GoogleAnalyticsService } from '~/shared/services/google-analytics.service';
import { JwtService } from '~/shared/services/jwt.service';

import { OrganizationService } from '../organization/organization.service';

import { MembershipDto } from './membership/membership-dto';
import { UserModel } from './model/user.model';
import { UserResponseTransformer } from './user-response.transformer';
import { UserEndpoint } from './user.endpoint';

@Injectable({ providedIn: 'root' })
export class UserService extends CrmUserService<UserModel> {
  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'users',
    endpoint: UserEndpoint,
    responseTransformer: UserResponseTransformer,
    listAllLimit: 50,
  })
  override endpoint!: UserEndpoint;

  readonly organizationService = inject(OrganizationService);
  readonly jwtService = inject(JwtService);
  readonly gaService = inject(GoogleAnalyticsService);

  private listAllNavigators$?: Observable<UserModel[]>;

  override login<Body>(body: Body, options?: object): Observable<UserModel> {
    const requestBody = { strategy: 'password', ...body };

    return super
      .login(requestBody, options)
      .pipe(
        this.setJwt(),
        this.setDefaultZone(),
        this.setAnalytics({ type: 'login' }),
      );
  }

  tokenLogin(token: string): Observable<UserModel> {
    return this.login({ railsLoginToken: token, strategy: 'railsTokenLogin' });
  }

  getRailsToken(): Observable<string> {
    return this.endpoint
      .request<{ loginToken: string }>('POST', 'rails-login-token')
      .pipe(map((response) => response.loginToken));
  }

  override getProfile(options?: CrmDictionary): Observable<UserModel> {
    return super
      .getProfile(options)
      .pipe(this.setDefaultZone(), this.setAnalytics({ type: 'profile' }));
  }

  override logout(): Observable<string> {
    return super.logout().pipe(
      tap(() => this.jwtService.resetToken()),
      tap(() => (Settings.defaultZone = new SystemZone())),
      catchError(() => EMPTY),
    );
  }

  override listUsers<Params>(
    params?: Params,
  ): Observable<CrmEndpointListResponse<UserModel>> {
    return super
      .listUsers(params)
      .pipe(catchError(() => of(getEmptyResponse<UserModel>())));
  }

  override listAllUsers<Params>(params?: Params): Observable<UserModel[]> {
    return super.listAllUsers(params).pipe(catchError(() => of([])));
  }

  listUsersSafe<Params>(
    ids: string[],
    params?: Params,
  ): Observable<UserModel[]> {
    return crmSafeListByIDs(
      (safeParams) =>
        this.listUsers({
          ...params,
          ...safeParams,
        }).pipe(map((response) => response.data)),
      ids,
      20,
    );
  }

  listBackofficeUsers<Params>(
    params?: Params,
  ): Observable<CrmEndpointListResponse<UserModel>> {
    return this.listUsers({ ...params, scope: 'backoffice' });
  }

  listNavigators<Params>(params?: Params) {
    return this.organizationService.organization$.pipe(
      switchMap((organization) => {
        return this.listUsers({
          'membership[$elemMatch][organization._id]': organization,
          'membership[$elemMatch][role]': 'navigator',
          'membership[$elemMatch][type]': 'cohort',
          ...params,
        });
      }),
    );
  }

  listAllNavigators<Params>(params?: Params): Observable<UserModel[]> {
    if (!this.listAllNavigators$) {
      this.listAllNavigators$ = this.organizationService.organization$.pipe(
        switchMap((organization) => {
          return this.listAllUsers({
            'membership[$elemMatch][organization._id]': organization,
            'membership[$elemMatch][role]': 'navigator',
            'membership[$elemMatch][type]': 'cohort',
            ...params,
          });
        }),
        finalize(() => {
          delete this.listAllNavigators$;
        }),
        share(),
      );
    }

    return this.listAllNavigators$;
  }

  import(body: FormData) {
    return this.endpoint.request('POST', 'import', { body });
  }

  merge(target: string, sources: string[]) {
    return this.endpoint.request('POST', 'merge', {
      body: { targetUser: target, sourceUsers: sources },
    });
  }

  invite(ids: string[]) {
    return this.endpoint.invite(ids);
  }

  report(ids: string[]) {
    return this.endpoint.request<HttpResponse<Blob>>('POST', 'report', {
      body: {
        _id: {
          $in: ids,
        },
      },
      responseType: 'blob',
      observe: 'response',
      headers: {
        Accept: 'text/csv',
      },
    });
  }

  deleteMembership(id: string, params: MembershipDto) {
    return this.endpoint.deleteMembership(id, params);
  }

  createMembership(id: string, body: MembershipDto) {
    return this.endpoint.createMembership(id, body);
  }

  resolveUser(id?: string) {
    if (!id) {
      return of(undefined);
    }

    return this.getUser(id);
  }

  protected setDefaultZone() {
    return tap((user: UserModel) => {
      if (user.timezone) {
        Settings.defaultZone = user.timezone;
      }
    });
  }

  protected setJwt() {
    return tap((user: UserModel) => {
      this.jwtService.setToken(user.token);
    });
  }

  protected setAnalytics(options: { type: 'login' | 'profile' }) {
    return tap((user: UserModel) => {
      this.gaService.set({ user_id: user._id });
      const event =
        options.type === 'login' ? GaActionEnum.LOGIN : 'return_to_page';
      this.gaService.event(event);
    });
  }
}
